* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: whitesmoke;
}

header {
  height: 100px;
  display: flex;
  align-items: center;
  background: #f59528; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #04f153,
    #f59528
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #04f153,
    #f59528
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

header > img {
  height: 80%;
  margin-left: 10%;
}

header > p {
  font-family: VT323, monospace;
  color: whitesmoke;
  font-size: 50px;
  margin-left: 60px;
}

.meme {
  position: relative;
  width: 90%;
  margin: auto;
}

.meme > img {
  width: 100%;
}

.meme > h2 {
  position: absolute;
  width: 80%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin: 15px 0;
  padding: 0 5px;
  font-family: impact, sans-serif;
  font-size: 4em;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000,
    -2px 2px 0 #000, 0 2px 0 #000, 2px 0 0 #000, 0 -2px 0 #000, -2px 0 0 #000,
    2px 2px 5px #000;
}

.meme > .bottom {
  bottom: 0;
}

.meme > .top {
  top: 0;
}

.meme-form {
  width: 90%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}

.meme-form > input {
  width: 45%;
  height: 40px;
}

.meme-form > button {
  border: none;
  font-family: VT323, monospace;
  font-size: 25px;
  letter-spacing: 1.5px;
  color: white;
  background: #6441a5;
}

.meme-form > input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: VT323, monospace;
  font-size: 25px;
  text-align: cen;
}
.meme-form > input::-moz-placeholder {
  /* Firefox 19+ */
  font-family: VT323, monospace;
  font-size: 25px;
  text-align: cen;
}
.meme-form > input:-ms-input-placeholder {
  /* IE 10+ */
  font-family: VT323, monospace;
  font-size: 25px;
  text-align: cen;
}
.meme-form > input:-moz-placeholder {
  /* Firefox 18- */
  font-family: VT323, monospace;
  font-size: 25px;
  text-align: cen;
}
